import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nav: null,
  menu: false,
  selected_product_id: null,
  selected_id: null,
  galleryId: null,
  loginStatus: false,
  footer: false,
  navItemList: [
    {
      id: 0,
      name: "Anasayfa",
      icon: "../images/icon/home-gold.png",
      active: "../images/icon/home-gold.png",
      href: "/"
    },
    {
      id: 1,
      name: "Modeller",
      icon: "../images/icon/sofa-gold.png",
      active: "../images/icon/sofa-gold.png",
      href: "/models"
    },
    {
      id: 2,
      name: "Hakkımızda",
      icon: "../images/icon/info-gold.png",
      active: "../images/icon/info-gold.png",
      href: "/about"
    },
    {
      id: 3,
      name: "Bize Ulaşın",
      icon: "../images/icon/location-gold.png",
      active: "../images/icon/location-gold.png",
      href: "/contact"
    },
  ],
  images: [
  ],
}

const UserManagament = createSlice({
  name: "UserManagament",
  initialState,
  reducers: {
    setNav: (state, action) => {
      state.nav = action.payload
    },
    setMenu: (state, acton) => {
      state.menu = !state.menu
    },
    setRecommend: (state, action) => {
      state.recommend = action.payload
    },
    setGallery: (state, action) => {
      state.galleryId = action.payload
    },
    setImages: (state, action) => {
      state.images = action.payload
    },
    setSelected_product: (state, action) => {
      state.selected_product_id = action.payload
    },
    setSelected_id: (state, action) => {
      let dizi = [action.payload]
      state.selected_id = dizi.select_id
    },
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload
    },
    setFooter: (state, action) => {
      state.footer = action.payload
    }
  }
})

export const { setNav, setMenu, setFooter, setLoginStatus, setImages, setSelected_product, setSelected_id, setGallery, setRecommend } = UserManagament.actions;
export default UserManagament.reducer