import React, { useEffect, useState } from 'react'
import Overview from './components/Overview';

import { Provider, useDispatch } from 'react-redux'
import { store } from './store/store'

import Header from './components/Header';
import Contents from './components/Contents';

import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { uploadBytes, getDownloadURL, ref, getStorage } from 'firebase/storage'
import { initializeApp } from "firebase/app";
import { BrowserRouter, Route, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import Models from './components/Models';
import Gallery from './components/Gallery';
import Admin from './components/Admin';
import Recommend from './components/Recommend';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';

const firebaseConfig = {
  apiKey: "AIzaSyB_MdrrDzK1jNS51Qwn9PGeei8AcE5m8a4",
  authDomain: "furniture-31f0f.firebaseapp.com",
  projectId: "furniture-31f0f",
  storageBucket: "furniture-31f0f.appspot.com",
  messagingSenderId: "806172987275",
  appId: "1:806172987275:web:3401e098e97460db67298e",
  measurementId: "G-G2C4NB8R35"
};

export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db }
export const storage = getStorage(app);

const App = () => {
  const [models, setModels] = useState()
  const [images, setImages] = useState()

  const mod = (a) => {
    setModels(a.target.value)
  }
  const image = (e) => {
    setImages(e.target.files[0])
  }

  const upload = async () => {
    try {
      const storageRef = ref(storage, `files/${images.name}`)
      const snapshot = await uploadBytes(storageRef, images)
      const fileURL = await getDownloadURL(snapshot.ref)
      setImages(fileURL)

      const dbRef = collection(db, 'models');
      const querysnapshot = await getDocs(dbRef);
      const id = querysnapshot.size;
      const newItem = doc(dbRef, id.toString());

      await setDoc(newItem, {
        model: models,
        image: fileURL,
      })
      setImages(null)
    } catch {
      console.log("Error")
    }
  }

  const [loading, setLoading] = useState(true);

  const HeaderWrapper = ({ children }) => {
    return (
      <>
        <Header />
        {children}
        <Footer />
      </>
    )
  }

  const { username } = useParams();

  return (
    <BrowserRouter className='bg-stone-200 h-full'>
      <div id="main">
        <Routes>
          <Route
            path='/'
            element={<HeaderWrapper><Recommend /></HeaderWrapper>}
          />
          <Route
            path='/models'
            element={<HeaderWrapper><Models /></HeaderWrapper>}
          />
          <Route
            path="/gallery/:galleryProductId"
            element={<HeaderWrapper><Gallery /></HeaderWrapper>}
          />
          <Route
            path='/about'
            element={<HeaderWrapper><About /></HeaderWrapper>}
          />
          <Route
            path='/contact'
            element={<HeaderWrapper><Contact /></HeaderWrapper>}
          />
          {/* <Route
            path='/admin'
            element={<Admin />}
          /> */}
          <Route
            path="/overview/:modelid"
            element={<HeaderWrapper><Overview /></HeaderWrapper>}
          />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

const Root = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default Root;