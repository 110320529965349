import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { collection } from 'firebase/firestore'
import { setFooter, setNav } from '../store/features/UserManagament';
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useParams } from 'react-router-dom';
import { db } from '../App';
import 'boxicons'
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Loading from './Loading';


const Overview = () => {
  const { images, selected_id, gallery, footer } = useSelector((state) => state.UserManagament);
  const { modelid } = useParams();
  const galleryRef = collection(db, 'gallery')
  const [galleryData] = useCollectionData(galleryRef)

  const dispatch = useDispatch()
  const [storageRef, setStorageRef] = useState()

  useEffect(() => {
    dispatch(setNav(1))
  }, [])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  useEffect(() => {
    document.title = "Soov Home | Model Detay"
  }, [])

  const [image, setImage] = useState(null)
  const [title, setTitle] = useState(null)
  const [text, setText] = useState(null)
  const [product_id, setProduct_id] = useState(null)

  useEffect(() => {
    galleryData && galleryData.forEach((gallery) => {
      if (gallery.id == modelid) {
        setImage(gallery.image)
        setTitle(gallery.title)
        setText(gallery.text)
        setProduct_id(gallery.product_id)
      }
    })
  }, [galleryData])

  const whatsappNumber = '905376919796';
  const { galleryid } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const simulateLoading = async () => {
      await new Promise(resolve => setTimeout(resolve, 750));
      setLoading(false);
    };
    simulateLoading();
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (


        <motion.div
          className='
          overview 
          h-fit 
          top-[25px] 
          min-h-screen 
          grid 
          grid-cols-2
          '
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            top: 0,
          }}
          exit={{ opacity: 0 }}
        >


          <div className="image w-full h-full bg-[#f2f2f2]">
            <img
              src={image}
              className='
              w-full 
              h-full 
              object-cover 
              scale-90 
              overview-image
              '
              alt="soovhome-gallery-image"
            />
          </div>


          <div className="text shadow-md">


            <div className="w-full overview-info-box h-full scale-90 flex flex-col justify-between">

              <div className="col">

                <span
                  className='
                  text-4xl 
                  mb-4 
                  font-extrabold
                  text-[#333333e5]
                  '
                >

                  {title}

                </span>


                <p
                  className='
                  text-[#61677A]
                  text-lg 
                  leading-8 
                  tracking-wide
                  '
                >
                  {text}
                </p>

              </div>


              <div className="row w-full justify-between">

                <div
                  className="
                  row 
                  select-none 
                  p-1 
                  shadow 
                  cursor-pointer 
                  hover:scale-110 
                  duration-300 
                  rounded-full 
                  border-4 
                  active:scale-90 
                  hover:border-0 
                  p-1 
                  w-[50px] 
                  h-[50px]
                  "
                >

                  <div className="border-[#333333] bg-[#333333] rounded-full w-full p-1 h-full">

                    <a
                      href={`/gallery/${product_id}`}
                    >

                      <ChevronLeftIcon
                        className='
                        relative 
                        text-white 
                        right-1
                        '
                      />

                    </a>

                  </div>

                </div>


                <div className="row gap-5">


                  <a
                    className='row items-center'
                    href={`https://wa.me/${whatsappNumber}`}
                  >

                    <img
                      src="../images/whatsapp.png"
                      className='duration-300
                       hover:rounded-md 
                       hover:p-[3px] 
                       hover:border-4 
                       w-[45px] 
                       h-[45px]
                       '
                      alt=""
                    />

                  </a>


                  <a
                    className='row items-center'
                    href="mailto:soovhome.com"
                  >

                    <img
                      src="../images/mail.png"
                      className='duration-300
                       hover:rounded-md 
                       hover:p-[3px] 
                       hover:border-4 
                       w-[45px] 
                       h-[45px]
                       '
                      alt=""
                    />

                  </a>


                  <a
                    className='row items-center'
                    href="https://www.instagram.com/soovhome/"
                  >

                    <img
                      src="../images/instagram.png"
                      className='duration-300
                       hover:rounded-md 
                       hover:p-[3px] 
                       hover:border-4 
                       w-[45px] 
                       h-[45px]
                       '
                      alt=""
                    />

                  </a>


                  <a
                    className='row items-center'
                    href="https://www.facebook.com/soovhome/"
                  >

                    <img
                      src="../images/facebook.png"
                      className='duration-300
                       hover:rounded-md 
                       hover:p-[3px] 
                       hover:border-4 
                       w-[45px] 
                       h-[45px]
                       '
                      alt=""
                    />

                  </a>


                </div>


              </div>


            </div>


          </div>


        </motion.div >
      )}
    </div>
  )
}

export default Overview