/* ================ HEROICONS  ================ */
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';

/* ================ REACT & HOOKS  ================ */
import React, { useEffect, useState } from 'react'

/* ================ REDUX  ================ */
import { setFooter, setNav } from '../store/features/UserManagament';
import { useDispatch } from 'react-redux';

/* ================ FRAMER-MOTİON  ================ */
import { motion } from "framer-motion";

/* ================ LOADİNG  ================ */
import Loading from './Loading';



const Contact = () => {
  /* ===== state ===== */
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const whatsappNumber = '905376919796';

  /* ===== redux-dispatch ===== */
  const dispatch = useDispatch()

  /* ===== setNav ===== */
  useEffect(() => {
    dispatch(setNav(3))
  }, []);

  /* ===== setFooter ===== */
  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  /* ===== loading ===== */
  useEffect(() => {
    const simulateLoading = async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      setLoading(false);
    };
    simulateLoading();
  }, []);

  /* ===== document - title ===== */
  useEffect(() => {
    document.title = "Soov Home | İletişim"
  }, [])

  return (
    <div className='contact h-fit'>
      {loading ? (
        <Loading />
      ) : (
        <div
          className="
          contact-items 
          grid 
          grid-cols-2 
          w-full 
          min-h-screen
          ">


          <div
            className={`
            contact-item 
            relative flex 
            contact-bg 
            shadow-md 
            items-center 
            justify-center 
            w-full
            ${isOpen ? 'active' : ''}
            `}>


            <motion.div
              layout
              data-isopen={isOpen}
              initial={{ borderRadius: 50 }}
              className={`
              box 
              parent 
              ${isOpen ? 'active' : ''}
              `}>


              <a
                href={`https://wa.me/${whatsappNumber}`}
                className="item"
              >

                <img
                  src="images/whatsapp-green.png"
                  className='icon'
                  alt=""
                />

              </a>


              <a
                href="https://www.instagram.com/soovhome/"
                className="item"
              >

                <img
                  src="images/instagram-pink.png"
                  className='icon'
                  alt=""
                />

              </a>


              <motion.div
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className={`
                xmark 
                flex 
                col-span-2 
                ${isOpen ? 'flex' : 'hidden'}
                `}
                onClick={() => {
                  setTimeout(() => {
                    setIsOpen(!isOpen)
                  }, 250);
                }}
              >

                <XMarkIcon
                  className='itemm'
                />

              </motion.div>


              <a
                href="https://www.facebook.com/soovhome/"
                className="item"
              >

                <img
                  src="images/facebook-blue.png"
                  className='icon'
                  alt=""
                />

              </a>


              <a
                href="mailto:soovhome.com"
                className="item"
              >

                <img
                  src="images/mail-blue.png"
                  className='icon'
                  alt=""
                />

              </a>


            </motion.div>


            <div
              onClick={() => setIsOpen(!isOpen)}
              className='
              barr 
              flex 
              col-span-2
              '>

              <Bars3Icon
                className='itemm'
              />

            </div>

          </div>


          <div
            className="
            contact-item 
            map 
            flex 
            items-center 
            justify-center 
            w-full 
            h-full
            ">

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.8393286511146!2d29.517273199999998!3d40.078995899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cbc9f907c9b94b%3A0x1f322b18124aae28!2sSOOV%20Home!5e0!3m2!1str!2str!4v1696367506889!5m2!1str!2str"
              referrerPolicy="no-referrer-when-downgrade"
              loading="lazy"
              className='
              w-full 
              h-full 
              max-lg:col-span-2
              '>

            </iframe>


          </div>


        </div>


      )}


    </div>
  )
}

export default Contact