import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserManagament, { setFooter, setGallery, setNav } from '../store/features/UserManagament'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { collection } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../App';

const Models = () => {
  const { images, footer } = useSelector((state) => state.UserManagament);
  const dispatch = useDispatch()

  const ref = collection(db, 'models')
  const [data] = useCollectionData(ref)

  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  useEffect(() => {
    document.title = "Soov Home | Modeller"
  }, [])

  useEffect(() => {
    dispatch(setNav(1))
  }, []);

  const { galleryid } = useParams();

  return (
    <div id='models'>
      {data && data.map((image, index) => (
        <a
          href={`/gallery/${image.product_id}`}
          key={index}
          onClick={() => {
            setGallery(index)
          }}
          className={`
          model-image
          ${index >= data.length - 1 && data.length % 2 === 1
              ?
              'col-span-2 min-h-[550px!important] max-h-[550px!important]'
              :
              ''
            }
          `}
        >

          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              top: 0,
            }}
            exit={{ opacity: 0 }}
            className="image"
          >

            <img
              src={image.image}
              className='image'
              alt=""
            />

          </motion.div>


          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            className="mobilCard"
          >

            <span className='titlee'>
              {image.title}
            </span>

            <div
              className="mobilCardLink"
            >
              <span>Galeri Görüntüle</span>

              <ArrowRightIcon
                className='icon'
              />
            </div>

          </motion.div>


          <div className='text'>

            <div className="col">

              <span className='titlee'>{image.title}</span>

              <span className='textt'>
                Galeri Görüntüle
              </span>

            </div>

          </div>


        </a>
      ))


      }
    </div >
  )
}

export default Models
