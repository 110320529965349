/* ================ HEROICONS  ================ */
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'

/* ================ REDUX  ================ */
import { setNav, setMenu, setFooter } from '../store/features/UserManagament'
import { useDispatch, useSelector } from 'react-redux'

/* ================ REACT & HOOKS  ================ */
import React, { useEffect } from 'react'


const Header = () => {
  /* ===== state ===== */
  const { navItemList, nav, menu } = useSelector((state) => state.UserManagament);

  /* ===== redux-dispatch ===== */
  const dispatch = useDispatch()

  /* ===== menu ===== */
  useEffect(() => {
    if (menu == true) {
      let doc = document.querySelector(".rightMenu").style.position = "fixed"
    }
    else {
      let doc = document.querySelector(".main-header").style.position = "relative"
    }
  }, [menu])

  return (
    <header
      id='header'
      className='main-header'
    >


      <div
        className='
        nav 
        lg:container
        '>

        <div
          className="brand"
        >

          <a
            href="/"
          >

            <img
              src="../images/logo/soovlogo-gold.png"
              className='brand-image'
              draggable="false"
              alt="soovhome-logo"
            />

          </a>

        </div>

        <ul
          className="navList"
        >

          {navItemList && navItemList.map((item, index) => (
            <a
              href={item.href}
              onClick={() => {
                dispatch(setNav(index))
                dispatch(setFooter(false))
              }}
              key={index}
              className={`
              navLink 
              ${nav === item.id ? 'active' : ''}
              `}>

              <img
                src={`
                ${nav === item.id ? item.active : item.icon}`}
                className='navLinkImage'
                alt="Soov-Home"
              />

              <li
                className='navItem'>
                {item.name}
              </li>

            </a>

          ))
          }
        </ul>

        <Bars3BottomRightIcon
          className='menu'
          onClick={() => { dispatch(setMenu()) }}
        />

      </div>

      <div
        className={`
        rightMenu 
        ${menu ? 'active' : ''}
        `}>

        <XMarkIcon
          onClick={() => { dispatch(setMenu()) }}
          className={`
          menu 
          ${menu ? '' : 'active'}
          `}
        />

        <ul
          className="rightMenuList"
        >

          {navItemList && navItemList.map((item, index) => (

            <a
              href={item.href}
              onClick={() => {
                dispatch(setNav(index))
                dispatch(setFooter(false))
              }}
              key={index}
              className={`
              rightMenuLink 
              ${nav === item.id ? 'active' : ''}
              `}>

              <li
                key={index}
                className={`
                rightMenuItem 
                ${nav === item.id ? 'active' : ''}
                `}
                onClick={() => {
                  dispatch(setNav(index))
                  dispatch(setMenu())
                }}
              >
                {item.name}
              </li>

            </a>

          ))
          }

        </ul>

      </div>

    </header >
  )
}

export default Header