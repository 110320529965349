import { setFooter, setGallery, setImages, setNav } from '../store/features/UserManagament';
import { Autoplay, Pagination, Navigation, Parallax } from 'swiper/modules';
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collection, doc, getDocs } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
import { db } from '../App'
import Loading from './Loading';
import 'swiper/css/pagination';
import 'swiper/css';

const Recommend = () => {

  const onAutoplayTimeLeft = (s, time, progress) => { };
  const { images, selected, galleryId, footer } = useSelector((state) => state.UserManagament);
  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  const ref = collection(db, 'models')
  const [data] = useCollectionData(ref)

  useEffect(() => {
    const simulateLoading = async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      setLoading(false);
    };
    simulateLoading();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  useEffect(() => {
    dispatch(setNav(0))
  }, [])

  useEffect(() => {
    document.title = "Soov Home | Anasayfa "
  }, [])

  useEffect(() => {
    fetchData()
  }, [data])

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(ref);
      const newId = querySnapshot.size;
      const newDocRef = doc(ref, newId.toString());

      const newData = []
      data && data.map((docc) => {
        if (docc.title == docc.title) {
          newData.push({
            image: docc.image,
          })
          dispatch(setImages({
            id: docc.id,
            image: docc.image,
            title: docc.title,
            text: docc.text,
          }))
        }
      });
      setGalleryData(newData)
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            top: 0,
          }}
          exit={{ opacity: 0 }}
          id='recommend'>


          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            speed={900}
            autoplay={{
              delay: 5100,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="mySwiper"
          >


            {data && data.map((recommend, index) => (
              <SwiperSlide
                key={index}
              >
                <img
                  src={recommend.image}
                  className='image'
                  alt=""
                />
              </SwiperSlide>
            ))}


          </Swiper>

          <Swiper
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            speed={1000}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Parallax, Navigation, Autoplay]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="mySwiper swiperr"
          >


            <div
              slot="container-start"
              className="parallax-bg"
              data-swiper-parallax="-23%"
            ></div>


            {data && data.map((recommend, index) => (
              <SwiperSlide key={index}>

                <h2 className='title'>
                  {recommend.title}
                </h2>

                <p className='text'>
                  {recommend.text}
                </p>

                <a href={`/gallery/${recommend.product_id}`}>
                  <button
                    className='view'
                    type='sumbit'
                    onClick={() => {
                      dispatch(setGallery(recommend.product_id))
                    }}
                  >
                    INCELE
                  </button>
                </a>

              </SwiperSlide>
            ))
            }


          </Swiper>


        </motion.div>
      )
      }
    </div >
  )
}

export default Recommend