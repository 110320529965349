import React from 'react';

const Loading = () => {
  return (
    <div
      className='
      h-[90vh] 
      flex 
      items-center 
      justify-center
      '>


      <div
        className="
        w-full 
        h-full 
        flex 
        items-center 
        justify-center
        ">

        <span className="loader"></span>

      </div>

    </div>
  );
};

export default Loading;