import React, { useEffect, useState } from 'react'
import { setFooter } from '../store/features/UserManagament';
import { useDispatch, useSelector } from 'react-redux';

const Footer = () => {
  /* ===== redux-state ===== */
  const { footer } = useSelector((state) => state.UserManagament);

  /* ===== redux-dispatch ===== */
  const dispatch = useDispatch()

  /* ===== state ===== */
  const [opac, setOpac] = useState(false);
  const whatsappNumber = '905376919796';


  /* ===== setFooter ===== */
  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  return (
    <div>
      <div
        id='footer'
        className={`
        max-lg:px-30 
        max-md:px-16
        py-6 
        ${footer ? 'opacity-1' : 'opacity-0'}
        `}
      >


        <div
          className="col"
        >


          <div
            className="footer-card 
            max-lg:w-full 
            lg:container"
          >


            <div
              className="footer-image"
            >

              <a
                href="/"
                className='link'
              >

                <img
                  alt=""
                  className='image'
                  src="../images/logo/soovlogo-gold.png"
                />

              </a>

            </div>

            <div
              className="
              col 
              footer-item
              ">

              <h2
                className='title'
              >
                HIZLI ERISIM
              </h2>

              <div
                className='link'
              >

                <div
                  className="col"
                >

                  <a
                    href="/"
                    className='text'
                  >

                    <li
                      className='disc'
                    >
                      Anasayfa
                    </li>

                  </a>

                  <a
                    href="/models"
                    className='text'
                  >

                    <li
                      className='disc'
                    >
                      Modeller
                    </li>

                  </a>

                </div>

              </div>

            </div>

            <div
              className="
              col 
              footer-item"
            >

              <h2
                className='title'
              >
                ILETISIM
              </h2>

              <div
                className='link'
              >

                <div
                  className="col"
                >

                  <a
                    href="/about"
                    className='text'
                  >

                    <li
                      className='disc'
                    >
                      Hakkımızda
                    </li>

                  </a>

                  <a
                    href="/contact"
                    className='text'
                  >

                    <li
                      className='disc'
                    >
                      İletişim
                    </li>

                  </a>

                </div>

              </div>

            </div>

            <div
              className="
              footer-item 
              social
              ">

              <h2
                className='title'
              >
                BIZE ULASIN
              </h2>

              <div
                className='link'
              >

                <div
                  className="
                  col 
                  gap-2
                  ">

                  <div
                    className="
                    row 
                    social-link 
                    gap-[10px!important]
                    cursor-[pointer!important]
                    ">

                    <img
                      src="../images/phone-green.png"
                      className='icon'
                      alt=""
                    />

                    <a
                      className='gray'
                      href={`https://wa.me/${whatsappNumber}`}
                    >

                      <span
                        className='
                        text-xs 
                        w5'
                      >
                        +90 537 691 97 96
                      </span>

                    </a>

                  </div>

                  <div
                    className="
                    row 
                    social-link 
                    gap-[10px!important]
                    cursor-[pointer!important]
                    ">

                    <img
                      src="../images/mail-blue.png"
                      className='icon'
                      alt=""
                    />

                    <a
                      className='gray'
                      href="mailto:soovhome.com"
                    >

                      <span
                        className='text-xs'
                      >
                        info@soovhome.com
                      </span>

                    </a>

                  </div>

                  <div
                    className="
                    row 
                    links 
                    mt-2 
                    justify-between
                    max-lg:hidden 
                    pb-2
                    ">

                    <a
                      href={`https://wa.me/${whatsappNumber}`}
                      className='
                      icon 
                      scale-95
                      hover:scale-105
                      duration-300
                      '>

                      <img
                        src="../images/whatsapp-green.png"
                        alt="soovhome-whatsapp"
                      />

                    </a>

                    <a
                      href="https://www.instagram.com/soovhome/"
                      className='
                      icon 
                      scale-95
                      hover:scale-105
                      duration-300
                      '>

                      <img
                        src="../images/instagram-pink.png"
                        alt="soovhome-instagram"
                      />

                    </a>

                    <a
                      href="https://www.facebook.com/soovhome/"
                      className='
                      icon 
                      scale-95
                      hover:scale-105
                      duration-300
                      '>

                      <img
                        src="../images/facebook-blue.png"
                        alt="soovhome-facebook"
                      />

                    </a>

                  </div>

                </div>

              </div>

            </div>

            <div
              className="
              footer-item 
              hidden 
              max-lg:flex
              ">

              <div
                className="
                row
                links
                mt-2
                items-end
                justify-around
                max-md:justify-around
                w-full
                border-r-2
                border-b-2
                max-md:border-2
                max-md:bg-white
                max-md:border-none
                border-[#ffa600]
                rounded-[5px]
                p-6
                max-md:p-4
                footer-social
                text-[#333333]
                ">

                <a
                  href={`https://wa.me/${whatsappNumber}`}
                  className="
                  row 
                  items-center 
                  active:scale-95 
                  duration-300
                  ">

                  <div
                    className='icon'
                  >

                    <img
                      src="../images/whatsapp-green.png"
                      alt="soovhome-whatsapp"
                    />

                  </div>

                  <span
                    className='
                    hidden 
                    font-bold 
                    ml-4 
                    text-lg
                    '>
                    | +90 537 691 97 96
                  </span>

                </a>

                <a
                  href="https://www.instagram.com/soovhome/"
                  className="
                  row 
                  items-center 
                  active:scale-95 
                  duration-300
                  ">

                  <div
                    className='icon'
                  >

                    <img
                      src="../images/instagram-pink.png"
                      alt="soovhome-instagram "
                    />

                  </div>

                  <span
                    className='
                    hidden 
                    font-bold 
                    ml-4 
                    text-lg
                    '>
                    | Soovhome
                  </span>

                </a>

                <a
                  href="https://www.facebook.com/soovhome/"
                  className="row items-center active:scale-95 duration-300"
                >

                  <div
                    className='icon'
                  >

                    <img
                      src="../images/facebook-blue.png"
                      alt="soovhome-facebook"
                    />

                  </div>

                  <span
                    className='
                    hidden 
                    font-bold 
                    ml-4 
                    text-lg
                    '>
                    | Soov Home
                  </span>

                </a>

              </div>

            </div>

            <div
              className="
              col 
              footer-item
              ">

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.8393286511146!2d29.517273199999998!3d40.078995899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cbc9f907c9b94b%3A0x1f322b18124aae28!2sSOOV%20Home!5e0!3m2!1str!2str!4v1696367506889!5m2!1str!2str"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className='
                w-full 
                max-lg:col-span-2 
                max-h-[150px]
                '>
              </iframe>

            </div>

            <div
              className="
              col 
              footer-item 
              col-span-5 
              opacity-25
              ">

              <div
                className="
                w-full 
                h-[1px] 
                bg-white
                ">
              </div>

              <div
                className="
                w-full 
                my-4 
                text-lg
                text-white
                ">
                © 2023 / Soov Home | Tüm Hakları Saklıdır.
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default Footer
