/* ================ redux ================ */
import { setFooter, setNav } from '../store/features/UserManagament';
import { useDispatch } from 'react-redux';

/* ================ react & hooks ================ */
import React, { useEffect, useState } from 'react'

/* ================ framer-motion  ================ */
import { motion } from 'framer-motion'

/* ================ loading  ================ */
import Loading from './Loading';



const About = () => {
  /* ===== state ===== */
  const [loading, setLoading] = useState(true);

  /* ===== redux-dispatch ===== */
  const dispatch = useDispatch()

  /* ===== loading-control ===== */
  useEffect(() => {
    const simulateLoading = async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      setLoading(false);
    };
    simulateLoading();
  }, []);

  /* ===== setFooter ===== */
  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  /* ===== setNav ===== */
  useEffect(() => {
    dispatch(setNav(2))
  }, []);

  /* ===== document - title ===== */
  useEffect(() => {
    document.title = "Soov Home | Hakkımızda"
  }, [])

  return (
    <div
      className='
      about 
      min-h-screen
    '>
      {loading ? (
        <Loading />
      ) : (


        <motion.div
          className="
          about-box 
          top-[20px] 
          bg-stone-200
          "
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            top: 0,
          }}
          exit={{ opacity: 0 }}
        >


          <div
            className="
            about-box-items 
            row 
            h-full 
            w-full
            ">


            <div
              className="
              flex 
              flex-col 
              gap-10  
              w-full 
              h-full 
              about-box-item
              ">


              <div className="flex flex-col">
                <span>Hoş Geldiniz SoovHome'a!</span>
                <p className='mt-2'>SoovHome, konfor ve şıklığı bir araya getirerek evinizi yeniden tanımlamanıza yardımcı olan bir koltuk markasıdır. Kaliteli malzemeler, zarif tasarımlar ve müşteri memnuniyeti odaklı hizmet anlayışımızla, evinizi daha sıcak ve davetkar bir yer haline getirmek için buradayız.</p>
              </div>
              <div className="col">
                <span>Hakkımızda</span>
                <p className='mt-2'>SoovHome, uzun yıllara dayanan sektör deneyimi ve tutkuyla, ev dekorasyonunuza özel bir dokunuş katmayı hedefleyen bir markadır. Her koltuğumuz, estetik tasarımlarla birleşen dayanıklılık ve konfor sunarak yaşam alanlarınıza değer katmayı amaçlar.</p>
              </div>
              <div className="col">
                <span>Vizyonumuz</span>
                <p className='mt-2'>SoovHome olarak, müşterilerimize kaliteli ve estetik ürünler sunarak ev yaşamını iyileştirmeyi hedefliyoruz. Vizyonumuz, sadece koltuk satmak değil, aynı zamanda evlerinizi daha kişisel ve rahat bir atmosferle doldurmanıza yardımcı olmak üzerinedir.</p>
              </div>
              <div className="col">
                <span>Ürünlerimiz</span>
                <p className='mt-2'>SoovHome'un koleksiyonu, çeşitli tarzlara ve ihtiyaçlara hitap eden özenle seçilmiş koltukları içerir. Modern ve klasik tasarımlar arasında seçim yapabilir, renk ve kumaş seçenekleri ile kişiselleştirebilirsiniz. Her bir ürünümüz, hem görsel çekicilik hem de konfor açısından en yüksek standartlara uyan özel tasarımlardan oluşur.</p>
              </div>
              <div className="col">
                <span>Müşteri Hizmetleri</span>
                <p className='mt-2'>SoovHome, müşteri memnuniyetini en üst düzeyde tutmayı taahhüt eder. Profesyonel ve deneyimli müşteri hizmetleri ekibimiz, sizlere ürün seçimi, sipariş süreci ve satış sonrası destek konularında yardımcı olmaktan mutluluk duyacaktır. Bizim için her müşteri değerlidir ve her talep, özenle ele alınır.</p>
              </div>


            </div>
            <div
              className="
              flex 
              flex-col 
              gap-10  
              w-full 
              h-full 
              about-box-item
              ">
              <div
                className="col"
              >


                <span>SoovHome Farkı Nedir?</span>
                <p className='mt-2'>Özgün Tasarımlar: Her bir koltuğumuz, tasarım ekibimizin yaratıcılığından doğan özgün detaylarla bezeli. Sadece oturmak değil, aynı zamanda bir sanat eserinin parçasına sahip olmak istiyorsanız, SoovHome sizin için burada.</p>
                <p className='mt-8'>Üstün Kalite: Kullanılan malzemelerde kaliteden ödün vermiyoruz. Sadece dayanıklılık değil, aynı zamanda uzun yıllar boyunca ilk günkü gibi şık görünüm sunuyoruz.
                </p>
                <p className='mt-8'>Müşteri Memnuniyeti: SoovHome ailesi olarak sizin memnuniyetiniz bizim için en önemli önceliktir. Sorularınız ve talepleriniz için her zaman buradayız.</p>
                <p className='mt-8'>Evinizi daha özel kılmak, konfora yeni bir boyut eklemek istiyorsanız, SoovHome'u tercih edin. Sadece bir koltuk değil, yaşam tarzınızı yansıtan bir sanat eseri!</p>
                <p className='mt-8'>Hayalinizdeki oturma alanını keşfetmek için bizi ziyaret edin veya online mağazamızdan göz atın. SoovHome ile yaşam alanlarınıza değer katın!</p>
              </div>


            </div>
          </div>
        </motion.div>


      )}
    </div>
  )
}

export default About