/* ================ REDUX  ================ */
import { setNav, setFooter } from '../store/features/UserManagament'
import { useDispatch } from 'react-redux'

/* ================ REACT & HOOKS  ================ */
import React, { useEffect, useState } from 'react'

/* ================ HEROICONS  ================ */
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

/* ================ FIRESTORE  ================ */
import { collection, getDocs, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from '../App'

/* ================ FRAMER-MOTİON  ================ */
import { motion } from 'framer-motion'

/* ================ REACT-ROUTER  ================ */
import { useParams } from 'react-router-dom'

/* ================ LOADİNG  ================ */
import Loading from './Loading'



const Gallery = () => {
  /* ===== redux-dispatch ===== */
  const dispatch = useDispatch()

  /* ===== loading ===== */
  const [loading, setLoading] = useState(true);

  /* ===== firestore ===== */
  const ref = collection(db, 'gallery')
  const [refGalleryData] = useCollectionData(ref)
  const [galleryData, setGalleryData] = useState([]);


  /* ===== loading ===== */
  useEffect(() => {
    const simulateLoading = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoading(false);
    };
    simulateLoading();
  }, []);

  /* ===== setFooter ===== */

  /* ===== setNav ===== */
  useEffect(() => {
    dispatch(setNav(1))
  }, [])

  /* ===== setFooter ===== */
  useEffect(() => {
    setTimeout(() => {
      dispatch(setFooter(true))
    }, 750);
  }, [])

  /* ===== document - title ===== */
  useEffect(() => {
    document.title = "Soov Home | Model - Galeri"
  }, [])

  /* ===== params ===== */
  const { galleryProductId } = useParams();

  /* ===== galleryData ===== */
  useEffect(() => {
    fetchDataa()
  }, [refGalleryData])

  const fetchDataa = async () => {
    try {
      const querySnapshot = await getDocs(query(ref, where('product_id', '==', parseFloat(galleryProductId))));
      const products = querySnapshot.docs;

      const newGalleryData = products.map((product) => ({
        item_id: product.data().id,
        image: product.data().image,
      }));

      setGalleryData(newGalleryData)
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  return (
    <div
      id="gallery"
    >
      {loading ? (
        <Loading />
      ) :
        galleryData && galleryData.map((item, index) => (
          <div
            key={index}
          >

            <motion.div
              className="gallery-card"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                top: 0,
              }}
              exit={{ opacity: 0 }}
            >

              <a
                href={`/overview/${item.item_id}`}
                className="
                w-full 
                h-full
                ">

                <img
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    top: 0,
                  }}
                  exit={{ opacity: 0 }}
                  src={item.image}
                  className="image"
                  alt="soovhome-gallery"
                />

                <div
                  className="gallery-card-details"
                >

                  <span
                    className="text"
                  >
                    Görüntülemek İçin Tıkla
                  </span>

                </div>

              </a>

            </motion.div>

            <div
              className="backPageLink"
            >

              <a
                href='/models'
              >

                <span
                  className="item"
                >

                  <ArrowLeftIcon
                    className="icon"
                  />
                  Geri Dön
                </span>

              </a>

            </div>

          </div>

        ))}

    </div>
  );
}

export default Gallery
